import React, { useState } from 'react';
import InputField from '../../../../components/Yard/InputField/InputField';
const DoubleInput = (props) => {
  const [gel, setGel] = useState(0);
  const [drum, setDrum] = useState(0);

  return (
    <div className="amount_name">
      <div className="amount_gel">
        <p>{props.first}</p>
        <InputField
          idName="amount_gel_id"
          className="amount_gel_input"
          value={gel}
          change={(e) => setGel(e)}
          type={'number'}
        />
      </div>
      <div className="amount_drum">
        <p>{props.second}</p>
        <InputField
          idName="amount_drum_id"
          className="amount_drum_input"
          value={drum}
          change={(e) => setDrum(e)}
          type={'number'}
        />
      </div>
    </div>
  );
};
export default DoubleInput;
